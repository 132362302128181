import { sortBy } from "lodash"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "src/components/Button"
import FormErrors from "src/components/FormErrors"
import FormField from "src/components/FormField"
import Input from "src/components/FormField/Input"
import Select from "src/components/FormField/Select"
import { frequencyType } from "src/constants/audit"
import { getListYears } from "src/helpers/years-list"
import useTranslation from "src/hooks/useTranslation"
import CustomParamlistFieldset from "src/macrocomponents/CustomParamlistFieldset"
import {
  actionsAuditTypes,
  createBusinessUnitByCompany,
  getCompanies,
  getUnitBusiness,
  processTypeList
} from "src/services/paramlists.service"
import { getUsers } from "src/services/user.service"
import RangeDatePicker from "../../RangeDatePicker"
import styles from "./style.module.scss"

export default function GeneralInformation({ onNext, year, defaultValues, initData }) {
  const router = useRouter()

  const { register, watch, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      auditName: !defaultValues ? "" : initData.selectedEvaluationName,
      auditType: router.pathname === "/" ? "ESP" : "PRG",
      businessUnit: router.pathname === "/" ? "" : null,
      processType: router.pathname === "/" ? "" : null,
      year,
      tpyeAudit: null,
      startDate: "",
      endDate: "",
      hours: "",
      auditLeader: "",
      leaderHours: "",
      company: ""
    }
  })

  const { t } = useTranslation()

  const AUDITTYPE_WITH_COMPANY_REQUIRED = frequencyType.SPECIAL.value

  const formValidations = useMemo(
    () => ({
      auditName: { required: true },
      businessUnit: router.pathname === "/" ? { required: true } : null,
      processType: router.pathname === "/" ? { required: true } : null,
      year: { required: true },
      auditType: { required: true },
      startDate: { required: true },
      endDate: { required: true },
      hours: { required: true },
      auditLeader: { required: true },
      leaderHours: { required: true },
      company: {
        validate: {
          required: (value) => {
            const w = watch("auditType")
            const isInvalid = w == AUDITTYPE_WITH_COMPANY_REQUIRED && !value
            return !isInvalid
          }
        }
      }
    }),
    [router, AUDITTYPE_WITH_COMPANY_REQUIRED, watch]
  )

  const watchAuditType = watch("auditType")

  const yearListFunc = () => {
    const list = getListYears()

    const found = list.find((el) => el.id == year)

    if (found) {
      found.selected = true
    }

    return list
  }

  const yearList = yearListFunc()

  const [businessUnitList, setBusinessUnitList] = useState({
    data: [],
    filtered: []
  })
  const [users, setUsers] = useState(null)
  const [processList, setProcessList] = useState(null)
  const [auditTypes, setAuditTypes] = useState(null)
  const [companies, setCompanies] = useState(null)
  const [companieSelected, setCompanieSelected] = useState(null)
  const [leaderHoursValidator, setLeaderHoursValidator] = useState(false)
  const [customParamlistValues, setCustomParamlistValues] = useState({})

  const formatUsers = (data) => {
    return data.map((el) => {
      return {
        id: el.id,
        label: (
          <div className={styles.option}>
            <span style={{ color: "#0a858d", display: "inline-block", minWidth: 28 }}>{el.sigla}</span>
            <span>
              {el.first_name} {el.last_name}
            </span>
          </div>
        )
      }
    })
  }

  const filterAndFormatBusinessUnitList = (_data, _companyId) => {
    setCompanieSelected(_companyId)
    let _businessUnitId

    if (!_businessUnitId) {
      const _sorted = sortBy(
        _data.filter((el) => el.empresa.id === _companyId),
        "created_at"
      )
      if (_sorted?.length) {
        _businessUnitId = _sorted[0].id
      }
    }

    const list = _data
      .filter((el) => el.empresa.id === _companyId)
      .map((el) => {
        return {
          id: el.id,
          label: el.nombre,
          selected: el.id === _businessUnitId
        }
      })

    if (!_businessUnitId) {
      list.unshift({
        id: _companyId,
        label: `-${t("main_ui.general.lb_choice_select")}-`,
        selected: true
      })
    }

    if (_businessUnitId) {
      const automaticBusinessId = list.filter((el) => {
        if (el.selected === true) return el
      })

      setValue("businessUnit", automaticBusinessId[0].id, { shouldValidate: true })
    }

    return list
  }

  const handleChangeCustomParamListValue = (field, value) => {
    setCustomParamlistValues((prev) => {
      return { ...prev, [field]: value }
    })
  }

  const onSubmit = async (data) => {
    if (Number(data.leaderHours) > Number(data.hours)) {
      setValue("leaderHours", data.leaderHours, { shouldValidate: false })
      setLeaderHoursValidator(true)
    } else {
      if (Object.values(customParamlistValues).length > 0) {
        data.custom_paramlists = customParamlistValues
      }
      onNext(data)
    }
  }

  useEffect(() => {
    register("auditName", formValidations.auditName)
    register("auditType", formValidations.auditType)
    register("businessUnit", formValidations.businessUnit)
    register("processType", formValidations.processType)
    register("typeAudit", formValidations.auditType)
    register("year", formValidations.year)
    register("startDate", formValidations.startDate)
    register("endDate", formValidations.endDate)
    register("hours", formValidations.hours)
    register("auditLeader", formValidations.auditLeader)
    register("leaderHours", formValidations.leaderHours)
    register("company", formValidations.company)
  }, [register, formValidations])

  useEffect(() => {
    const getInitData = async () => {
      processTypeList().then(({ data: processData }) => {
        setProcessList(processData.map(({ id, nombre }) => ({ id, label: nombre })))
      })

      const [{ data: usersData }, { data: unitBusinessData }] = await Promise.all([getUsers(), getUnitBusiness()])

      getCompanies().then(({ data: companiesList }) => {
        setCompanies(companiesList.map(({ id, nombre }) => ({ id, label: nombre })))
      })
      setUsers(formatUsers(usersData.records))

      const filteredBusinessUnitList = filterAndFormatBusinessUnitList(unitBusinessData)

      setBusinessUnitList({
        data: unitBusinessData,
        filtered: filteredBusinessUnitList
      })

      const { data } = await actionsAuditTypes().GET()
      setAuditTypes(data?.map(({ id, nombre }) => ({ id, label: nombre })))

      const selectedId = filteredBusinessUnitList.find((el) => el.selected)?.id
      if (selectedId) {
        setValue("businessUnit", selectedId, { shouldValidate: false })
      }
    }

    getInitData()
  }, [])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='width-100-react-datepicker'
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <div style={{ display: "grid", gap: 15, overflow: "auto", paddingBottom: 10 }}>
        <div style={{ margin: "20px 0 10px" }}>{t("frontend.auditoria.creation.tab_infogeneral__description")}</div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 300px", gap: 15, alignItems: "center" }}>
          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              {t("frontend.auditoria.fields.name__long")}:<span className={styles.asterisk}>*</span>
            </label>
            <FormField classRequired={errors.auditName ? true : false}>
              <Input
                initialValue={!defaultValues ? "" : initData.selectedEvaluationName}
                onChange={(value) => {
                  setValue("auditName", value, {
                    shouldValidate: true
                  })
                }}
              />
            </FormField>
            <FormErrors errors={errors.auditName} />
          </div>

          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              Año en el Plan Anual de Auditorías:
              <span className={styles.asterisk}>*</span>
            </label>
            <FormField classRequired={errors.year ? true : false}>
              <Select
                selectionChange={({ id }) => {
                  setValue("year", id, {
                    shouldValidate: true
                  })
                }}
                initialOptions={yearList}
              />
            </FormField>
            <FormErrors errors={errors.year} />
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: router.pathname === "/" ? "1fr 1fr 1fr 1fr" : "1fr",
            gap: 15,
            alignItems: "center"
          }}
        >
          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              {t("frontend.auditoria.fields.auditoria_tipo")}: <span className={styles.asterisk}>*</span>
            </label>
            <FormField isLoading={auditTypes == null}>
              <Select
                selectionChange={({ id }) => {
                  setValue("typeAudit", id, { shouldValidate: true })
                }}
                initialOptions={auditTypes}
                addEmpty
              />
            </FormField>
            <FormErrors errors={errors.typeAudit} />
          </div>
          {watchAuditType == AUDITTYPE_WITH_COMPANY_REQUIRED && (
            <div className={styles.inputWrapper}>
              <label className={styles.label}>
                {t("frontend.auditoria.fields.proceso_tipo")}: <span className={styles.asterisk}>*</span>
              </label>
              <FormField isLoading={processList == null} classRequired={errors.processType ? true : false}>
                <Select
                  selectionChange={({ id, label }) => {
                    setValue("processType", id, { shouldValidate: true })
                  }}
                  initialOptions={processList}
                  addEmpty
                />
              </FormField>
              <FormErrors errors={errors.processType} />
            </div>
          )}

          {watchAuditType == AUDITTYPE_WITH_COMPANY_REQUIRED && (
            <div className={styles.inputWrapper}>
              <label className={styles.label}>
                {t("frontend.organizacion.empresa")}: <span className={styles.asterisk}>*</span>
              </label>
              <FormField isLoading={companies == null} classRequired={errors.company ? true : false}>
                <Select
                  selectionChange={({ id, label }) => {
                    setValue("company", id, { shouldValidate: true })

                    setBusinessUnitList((last) => {
                      return {
                        ...last,
                        filtered: filterAndFormatBusinessUnitList(last.data, id)
                      }
                    })
                  }}
                  initialOptions={companies}
                  addEmpty
                />
              </FormField>
              <FormErrors errors={errors.company} />
            </div>
          )}

          {watchAuditType == AUDITTYPE_WITH_COMPANY_REQUIRED && (
            <div className={styles.inputWrapper}>
              <label className={styles.label}>
                {t("frontend.organizacion.unidad_negocio")}: <span className={styles.asterisk}>*</span>
              </label>
              <FormField classRequired={errors.businessUnit ? true : false}>
                <Select
                  canAddDisabled={companieSelected ? false : true}
                  canAdd
                  onAdd={{
                    service: (value, cb) => {
                      return createBusinessUnitByCompany({ name: value, companyId: companieSelected }, cb)
                    },
                    success: () => {},
                    error: () => {}
                  }}
                  selectionChange={({ id }) => {
                    setValue("businessUnit", id, {
                      shouldValidate: true
                    })
                  }}
                  initialOptions={businessUnitList.filtered}
                />
              </FormField>
              <FormErrors errors={errors.businessUnit} />
            </div>
          )}
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 15 }}>
          <RangeDatePicker
            labelConfig={{
              start: (
                <>
                  {t("frontend.default.start_date")}: <span className={styles.asterisk}>*</span>
                </>
              ),
              end: (
                <>
                  {t("frontend.default.end_date")}: <span className={styles.asterisk}>*</span>
                </>
              ),
              position: "up",
              bold: true,
              twoPoints: false,
              style: {
                width: "fit-content"
              }
            }}
            format='yyyy-MM-dd'
            initialStartDateValue={""}
            initialEndDateValue={""}
            startChange={([completeDate, visualDate]) => {
              setValue("startDate", visualDate, { shouldValidate: true })
            }}
            endChange={([completeDate, visualDate]) => {
              setValue("endDate", visualDate, { shouldValidate: true })
            }}
            errorsFormStart={errors.startDate}
            errorsFormEnd={errors.endDate}
          />
          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              {t("frontend.auditoria.fields.estimated_hours__long")}: <span className={styles.asterisk}>*</span>
            </label>
            <FormField classRequired={errors.hours ? true : false}>
              <Input
                type='number'
                min={0}
                max={32767}
                initialValue={""}
                onChange={(value) => {
                  setLeaderHoursValidator(false)
                  setValue("hours", value, {
                    shouldValidate: true
                  })
                }}
              />
            </FormField>
            <FormErrors errors={errors.hours} />
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 15 }}>
          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              {t("frontend.auditoria.fields.leader__long")} <span className={styles.asterisk}>*</span>
            </label>
            <FormField isLoading={users == null} classRequired={errors.auditLeader ? true : false}>
              <Select
                selectionChange={({ id, label }) => {
                  setValue("auditLeader", id, { shouldValidate: true })
                }}
                initialOptions={users}
                addEmpty
              />
            </FormField>
            <FormErrors errors={errors.auditLeader} />
          </div>
          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              {t("frontend.auditoria.fields.leader_estimated_hours")}: <span className={styles.asterisk}>*</span>
            </label>
            <FormField classRequired={errors.leaderHours ? true : false}>
              <Input
                type='number'
                min={0}
                max={32767}
                initialValue={""}
                onChange={(value) => {
                  setLeaderHoursValidator(false)
                  setValue("leaderHours", value, {
                    shouldValidate: true
                  })
                }}
              />
            </FormField>
            <FormErrors errors={errors.leaderHours} />
            {leaderHoursValidator === true ? (
              <div style={{ fontWeight: "600", color: "var(--accent-red)" }}>
                {t("frontend.auditoria.creation.error_leader_estimated_hours")}
              </div>
            ) : null}
          </div>
        </div>
        <CustomParamlistFieldset scope='auditoria' onChange={handleChangeCustomParamListValue} />
      </div>
      <div style={{ display: "flex", alignItems: "flex-end", paddingTop: 10, flex: 1 }}>
        <span style={{ flex: "1" }} />
        <Button text='btn_next' name='normal' type='big' option='normal' submit={true} />
      </div>
    </form>
  )
}
