import cx from "classnames"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import useAccessControl from "src/hooks/useAccessControl"
import Button from "../Button"

import styles from "./style.module.scss"

const TabHeader2 = ({ active, onClick, label, badgeCallback = null }) => {
  const [badge, setBadge] = useState(null)

  useEffect(() => {
    if (badgeCallback) {
      setBadge("@loading")
      Promise.resolve(badgeCallback()).then((value) => {
        setBadge(value)
      })
    }
  }, [])

  return (
    <div className={cx(styles.tabheader2, active && styles.active)} onClick={onClick}>
      <span>&nbsp;</span>
      <span className={styles.label}>{label}</span>
      {badge !== null && (
        <span className={styles.badge}>
          {badge == "@loading" ? (
            <span className={cx("ui loader active inline mini blue")}>&nbsp;</span>
          ) : (
            <span className={cx("ui label circular", badge != 0 ? "red" : "grey")}>{badge}</span>
          )}
        </span>
      )}
    </div>
  )
}

export default function TabTwo({
  config = [],
  initialTab = null,
  currentTab = null,
  mini = false,
  tabRoutePreffix = null,
  headerDesign = 1,
  headerStyle = {},
  onChange = (props) => {},
  style = {},
  contentStyle = {}
}) {
  const router = tabRoutePreffix !== null ? useRouter() : null
  const { hasPermission } = useAccessControl()

  if (initialTab === null || undefined) {
    if (tabRoutePreffix === null) {
      initialTab = 0
    } else {
      const curTab = router.query[tabRoutePreffix]
      if (curTab && config[curTab]) {
        initialTab = curTab
        onChange({
          selectedTabId: config[curTab].id,
          selectedTabIndex: curTab,
          selectedTabLabel: config[curTab].headerLabel,
          selectedTab: config[curTab]
        })
      } else {
        initialTab = 0
      }
    }
  }

  const [tabSelected, setTabSelected] = useState(initialTab)
  const [renderedTabs, setRenderedTabs] = useState([initialTab])

  useEffect(() => {
    if (initialTab === 0 && mini === true) {
      if (`${router.query?.tab}` != `${initialTab}`) {
        router.push({
          query: { ...router.query, tab: initialTab }
        })
      }
    }
    setTabSelected(initialTab)
  }, [initialTab])

  const onClickHandler = (el, idx) => {
    let onRouterPush
    if (tabRoutePreffix !== null) {
      onRouterPush = router.push({
        query: { ...router.query, [tabRoutePreffix]: idx }
      })
    } else {
      onRouterPush = Promise.resolve()
    }

    onRouterPush.then(() => {
      setTabSelected(idx)
      if (currentTab) {
        currentTab(idx)
      }

      setRenderedTabs((last) => {
        const updatedData = new Set([...last])
        updatedData.add(idx)

        return Array.from(updatedData)
      })

      onChange({
        selectedTabId: el.id,
        selectedTabIndex: idx,
        selectedTabLabel: config[idx].headerLabel,
        selectedTab: config[idx]
      })
    })
  }

  const renderHeader = () => {
    let header = null

    switch (headerDesign) {
      case 1:
        header = (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              ...headerStyle
            }}
          >
            {config.map((el, index) => {
              if (el.permission && !hasPermission(el.permission)) {
                return null
              }

              return el ? (
                <Button
                  key={index}
                  text={el.headerLabel}
                  name={index + "" === tabSelected + "" ? "green" : "gray"}
                  type='big'
                  handleClick={onClickHandler.bind(this, el, index)}
                />
              ) : null
            })}
          </div>
        )
        break
      case 2:
        header = (
          <div
            style={{
              display: "flex",
              gap: 10,
              marginBottom: 5,
              ...headerStyle
            }}
          >
            {config.map((el, index) => {
              if (el.permission && !hasPermission(el.permission)) {
                return null
              }

              return (
                <TabHeader2
                  key={index}
                  active={index + "" === tabSelected + ""}
                  onClick={onClickHandler.bind(this, el, index)}
                  label={el.headerLabel}
                  badgeCallback={el.headerBadge}
                />
              )
            })}
          </div>
        )
        break
    }

    return header
  }

  const renderTabContent = (child, index) => {
    if (typeof child.content === "function") {
      if (`${index}` === `${tabSelected}`) {
        return child.content()
      }
    } else {
      return child.content
    }

    return null
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        maxWidth: "calc(100vw - 66px)",
        ...style
      }}
    >
      {renderHeader()}

      {config.map((child, index) => {
        if (index != tabSelected && !renderedTabs.includes(index)) {
          return null
        }
        return (
          <div
            key={index}
            style={{
              display: index == tabSelected ? "block" : "none",
              flex: 1,
              ...contentStyle
            }}
          >
            {renderTabContent(child, index)}
          </div>
        )
      })}
    </div>
  )
}
