import { useState } from "react"
import { COPY_OPTIONS_SCHEMA } from "src/constants/audit"
import CopyOptions from "src/macrocomponents/CreateRisk/CopyOptions"
import SelectProjectForm from "src/macrocomponents/SelectProjectForm"

export default function CopyExistingAudit({ onChange }) {
  const [projectInfo, setProjectInfo] = useState(null)
  const [copyOptions, setCopyOptions] = useState({ control_evaluacion: true })

  const onSelectProject = (v) => {
    setProjectInfo(v)
    onChange(v ? { copy_from: v.id, copy_options: copyOptions } : null)
  }

  const onChangeCopyOptions = (v) => {
    if (!v || Object.keys(v).length == 0) {
      v = null
    }
    setCopyOptions(v)
    onChange(projectInfo ? { copy_from: projectInfo.id, copy_options: v } : null)
  }

  return (
    <div style={{ margin: "10px auto 5px", width: "90%", height: "99%" }}>
      <div className='ui two column stackable divided grid' style={{ height: "99%" }}>
        <div className='row' style={{ height: "99%" }}>
          <div className='sixteen wide tablet six wide computer eight wide large screen column'>
            <p style={{ textAlign: "center" }}>Seleccione la auditoría que desea copiar:</p>
            <SelectProjectForm
              value={projectInfo}
              onSelect={onSelectProject}
              projectType='auditoria'
              labelPosition='top'
              labels={{ year: "Año del Plan de Auditoría", project: "Auditoría" }}
            />
          </div>
          <div
            className='sixteen wide tablet ten wide computer eight wide large screen column '
            style={{ height: "100%" }}
          >
            <p style={{ textAlign: "center" }}>Seleccione las opciones que desea copiar:</p>
            <CopyOptions
              typeTree='auditPlan'
              projectType='auditoria'
              schema={COPY_OPTIONS_SCHEMA}
              onSelect={onChangeCopyOptions}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
