import cx from "classnames"
import sortBy from "lodash/sortBy"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import AdvancedTable from "src/components/AdvancedTable"
import Button from "src/components/Button"
import { DeleteItemAction } from "src/components/DeleteItemAction"
import Dialog from "src/components/Dialog"
import Divider from "src/components/Divider"
import Checkbox from "src/components/FormField/Checkbox"
import Icon from "src/components/Icon"
import InputSearch from "src/components/InputSearch"
import Loading from "src/components/Loading"
import Pill from "src/components/Pill"
import RadioGroup from "src/components/RadioGroup"
import RadioButton from "src/components/RadioGroup/RadioButton"
import WizardContentButtonSwitch from "src/components/Wizard/ButtonSwitch"
import { findFromConfigList } from "src/helpers/tree"
import useTranslation from "src/hooks/useTranslation"
import ProjectTree from "src/macrocomponents/ProjectTree"
import { getGenericSetting } from "src/services/accountSettings.service"
import { auditTemplatesServices } from "src/services/audit.service"
import { getEvaluations, getListObjectGenericAction, getListRisks } from "src/services/evaluation.service"
import { getGeneralRisks, getObjectiveRisks } from "src/services/paramlists.service"
import styles from "./style.module.scss"

const columnRef = {
  inherentRisk: "riesgo_inherente",
  residualRisk: "riesgo_residual"
}

const TemplateContext = createContext(null)

export default function AuditNewScopeSelectType({
  onBack = () => {},
  onCreate = () => {},
  riskEvaluationData = "",
  empresaId = null,
  divisionId = null
}) {
  const [step, setStep] = useState(1)
  const [type, setType] = useState(null)

  const [auditTemplates, setAuditTemplates] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    auditTemplatesServices()
      .LIST()
      .then(({ data }) => {
        setAuditTemplates(data)
      })
  }, [])

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <p>{t("frontend.auditoria.creation.new_scope.description")}</p>
            <WizardContentButtonSwitch
              design='mini'
              buttons={[
                {
                  label: "frontend.auditoria.creation.new_scope.type_risks",
                  value: "risks"
                },
                {
                  label: "frontend.auditoria.creation.new_scope.type_items",
                  value: "items"
                }
              ]}
              defaultValue={type}
              onSelect={(v) => {
                setType(v)
                setStep(2)
              }}
            />
            <div className='ui divider'></div>
            <div className='buttons' style={{ margin: "10px auto" }}>
              <button onClick={onBack} className='ui left floated button'>
                &laquo; {t("main_ui.general.btn_back")}
              </button>
            </div>
          </>
        )
      case 2:
        if (auditTemplates === null) {
          return (
            <div className='ui segment basic' style={{ minHeight: 180 }}>
              <div className='ui loader active small'></div>
            </div>
          )
        }
        switch (type) {
          case "risks":
            return (
              <TemplateContext.Provider value={auditTemplates}>
                <ScopeRisk
                  onBack={() => setStep(1)}
                  onCreate={onCreate}
                  riskEvaluationData={riskEvaluationData}
                  divisionId={divisionId}
                  empresaId={empresaId}
                />
              </TemplateContext.Provider>
            )
          default:
            return (
              <TemplateContext.Provider value={auditTemplates}>
                <ScopeProcess
                  onBack={() => setStep(1)}
                  onCreate={onCreate}
                  riskEvaluationData={riskEvaluationData}
                  divisionId={divisionId}
                  empresaId={empresaId}
                />
              </TemplateContext.Provider>
            )
        }
    }
  }

  return renderStepContent()
}

function AuditTemplateSelector({ value = null, onChange }) {
  const [selected, setSelected] = useState(value)

  const selectableTemplates = useContext(TemplateContext)

  const { t } = useTranslation()

  useEffect(() => {
    if (value !== selected) {
      setSelected(value)
    }
  }, [value])

  const handleChange = (val) => {
    setSelected(val)
    onChange(val)
  }

  return (
    <div className={cx(styles.templateSelector, "ui container")}>
      <p>{t("frontend.auditoria.creation.new_scope.lb_select_template__description")}</p>
      <RadioGroup
        color='#0a858d'
        className={cx("ui vertical menu link fluid", styles.templatesRadiolist)}
        initialValue={selected}
        onRadioChange={handleChange}
      >
        {selectableTemplates?.length > 0 && (
          <div className='item header'>{t("frontend.auditoria.template.choices.custom")}</div>
        )}
        {selectableTemplates?.map((template) => {
          return (
            <div className={cx("item", styles.customTemplate)} key={template.id}>
              <RadioButton value={`@template:${template.id}`}>{template.name}</RadioButton>
            </div>
          )
        })}

        <div className='item header'>{t("frontend.auditoria.template.choices.default")}</div>
        <div className='item'>
          <RadioButton value='auditoria_base'>{t("frontend.auditoria.template.choices.default_template")}</RadioButton>
        </div>
      </RadioGroup>
    </div>
  )
}

function ScopeRiskGroup({ defaultOpen = false, onChange, riskEvaluationData, divisionId = null, empresaId, onDelete }) {
  const [groupName, setGroupName] = useState(null)
  const [open, setOpen] = useState(defaultOpen)
  const [data, setData] = useState(null)
  const [columnSort, setColumnSort] = useState("-residualRisk")

  const { t } = useTranslation()

  const [inherentRiskList, setInherentRiskList] = useState(null)

  const [riesgoRiRanges, setRiesgoRiRanges] = useState(null)

  const [selectAll, setSelectAll] = useState(false)
  const [textToFilter, setTextToFilter] = useState("")

  const [organizationalObjectiveList, setOrganizationalObjectiveList] = useState(null)

  const [macroprocesoList, setMacroprocesoList] = useState(null)

  const [generalRiskList, setGeneralRiskList] = useState(null)

  const mapDefaultChecked = function (list) {
    list.forEach((el) => {
      el.checked = true
    })

    return list
  }

  const getBaseData = async () => {
    const macroprocesos = await getListObjectGenericAction(riskEvaluationData, "macroprocesos", {
      subdivision: divisionId
    })
    macroprocesos.data.macroprocesos.push({ id: null, nombre: "-" + t("main_ui.general.lb_undefined") + "-" })

    setMacroprocesoList(mapDefaultChecked(macroprocesos.data.macroprocesos))

    const params = {}
    if (divisionId) {
      params.subdivision = divisionId
    }
    const risks = await getListRisks(riskEvaluationData, params)
    setData(risks.data.map((item) => ({ ...item, checked: true, visible: true })))
    setSelectAll(risks.data.length > 0)
  }

  const getListData = async () => {
    let items = await getGeneralRisks()
    items.data.push({ id: null, nombre: "- " + t("main_ui.general.lb_undefined") + " -" })
    setGeneralRiskList(mapDefaultChecked(items.data))

    items = await getObjectiveRisks()
    items.data.push({ id: null, nombre: "- " + t("main_ui.general.lb_undefined") + " -" })
    setOrganizationalObjectiveList(mapDefaultChecked(items.data))

    getGenericSetting("auditoria_tree_riesgo_ri_ranges", empresaId).then(({ data }) => {
      setRiesgoRiRanges(data.valor)
      setInherentRiskList(
        data.valor
          .filter((x) => x.is_active)
          .map((x) => ({ color: x.color, label: x.name, id: x.value, checked: true }))
          .concat([{ id: null, color: "#ccc", label: t("main_ui.general.lb_unrated"), checked: true }])
      )
    })
  }

  const onChangeSort = (column) => {
    setColumnSort(column)
  }

  const sortData = useCallback(() => {
    const sortConfig = columnSort.substring(0, 1) == "-" ? [columnSort.substring(1), -1] : [columnSort, 1]

    const sortedData = sortBy(data, (row) => {
      return row[columnRef[sortConfig[0]]] ?? 0
    })

    return sortConfig[1] == -1 ? sortedData.reverse() : sortedData
  }, [data, columnSort])

  useEffect(() => {
    getListData()

    const params = {}
    if (divisionId) {
      params.subdivision = divisionId
    }
    getListObjectGenericAction(riskEvaluationData, "record_name", params).then(({ data }) => {
      let name = data.evaluacion.name
      if (data.subdivision?.name) {
        name += " > " + data.subdivision?.name
      }
      setGroupName(name)
    })
  }, [])

  useEffect(() => {
    if (!data) {
      return
    }

    const selectedRisks = data.filter((el) => el.visible && el.checked)
    const arrayRisk = []
    selectedRisks.map((el) => {
      arrayRisk.push(el.id)
    })

    onChange(arrayRisk)
  }, [data])

  useEffect(() => {
    setMacroprocesoList(null)
    setData(null)
    if (riskEvaluationData !== null) {
      setSelectAll(false)
      getBaseData()
    }
  }, [riskEvaluationData])

  const matchsFilter = function (el) {
    const regex = new RegExp(textToFilter, "i")

    const inherentRiskFilter = () => {
      return inherentRiskList?.find((_el) => {
        return _el.id == el.riesgo_inherente
      })?.checked
    }

    const searchFilter = () => {
      return textToFilter === ""
        ? true
        : regex.test(el.codigo) ||
            regex.test(el.nombre) ||
            regex.test(el.riesgo_general?.nombre) ||
            regex.test(el.objetivos[0]?.nombre) ||
            regex.test(el.macroproceso?.nombre)
    }

    const generalRiskFilter = () => {
      return generalRiskList?.find(
        (_el) => (el.riesgo_general === null && _el.id === null) || _el.id === el.riesgo_general?.id
      )?.checked
    }

    const organizationalObjectiveFilter = () => {
      const isInTheFilter = (_id) => {
        return organizationalObjectiveList?.find((_el) => _el.id === _id).checked
      }

      if (el.objetivos.length == 0 && organizationalObjectiveList?.find((_el) => _el.id === null).checked) {
        return true
      }

      return el.objetivos.find((_el) => isInTheFilter(_el.id))
    }

    const macroprocesoFilter = () => {
      return macroprocesoList?.find(
        (_el) => (el.macroproceso === null && _el.id === null) || _el.id === el.macroproceso?.id
      )?.checked
    }

    return (
      inherentRiskFilter() &&
      generalRiskFilter() &&
      organizationalObjectiveFilter() &&
      macroprocesoFilter() &&
      searchFilter()
    )
  }

  const validateSelectAllCheckbox = (updatedData) => {
    const isAllSelected = updatedData.length > 0 && !updatedData.some((el) => !el.checked)
    setSelectAll(isAllSelected)
  }

  useEffect(() => {
    if (data === null) {
      return
    }

    const updatedData = [...data]
    updatedData.forEach((el) => {
      const isVisible = matchsFilter(el)
      el.visible = isVisible
    })

    validateSelectAllCheckbox(updatedData)

    setData(updatedData)
  }, [inherentRiskList, generalRiskList, organizationalObjectiveList, macroprocesoList, textToFilter])

  const onCheckRow = (row, val, event) => {
    if (event) {
      event.stopPropagation()
    }

    const updatedData = [...data]

    const found = updatedData.find((el) => el.id === row.id)

    found.checked = val

    validateSelectAllCheckbox(updatedData)

    setData(updatedData)
  }

  const onSelectAll = (val) => {
    const updatedData = [...data]

    updatedData.forEach((el) => (el.checked = val))

    setData(updatedData)

    setSelectAll(val)
  }

  const onSearchHandler = (value) => {
    setTextToFilter(value)
  }

  const onRiskInherentCheck = (riskId, check) => {
    setInherentRiskList((last) => {
      const updatedList = [...last]

      const found = updatedList.find((el) => el.id === riskId)

      found.checked = check

      return updatedList
    })
  }

  const onGeneralRiskCheck = (generalRiskId, check) => {
    setGeneralRiskList((last) => {
      const updatedList = [...last]

      const found = updatedList.find((el) => el.id === generalRiskId)

      found.checked = check

      return updatedList
    })
  }

  const onOrganizationObjectiveCheck = (organizationObjectiveId, check) => {
    setOrganizationalObjectiveList((last) => {
      const updatedList = [...last]

      const found = updatedList.find((el) => el.id === organizationObjectiveId)

      found.checked = check

      return updatedList
    })
  }

  const onMacroprocesoCheck = (id, check) => {
    setMacroprocesoList((last) => {
      const updatedList = [...last]
      const found = updatedList.find((el) => el.id === id)
      found.checked = check

      return updatedList
    })
  }

  const selectedRisks = data ? data.filter((el) => el.visible && el.checked) : []

  return (
    <div className={styles.group}>
      <h3 className={cx("ui top attached header", styles.groupTitle)}>
        {groupName ? (
          <div onClick={() => setOpen((prev) => !prev)}>
            <span>
              <i className='ui icon caret right' /> {groupName}
            </span>
            <div style={{ display: "flex" }}>
              {onDelete && (
                <DeleteItemAction onDelete={onDelete} confirmText={t("main_ui.general.delete_confirmation")} />
              )}
              <span className='ui label circular blue'>{selectedRisks.length}</span>
            </div>
          </div>
        ) : (
          <div className='ui loader inline active mini'></div>
        )}
      </h3>
      <div className='ui attached segment' style={{ display: open ? "block" : "none" }}>
        <div style={{ fontWeight: 600, margin: 10 }}>
          {t("frontend.auditoria.creation.new_scope.type_risk_filter__description")}
        </div>

        <div style={{ display: "flex", gap: 20 }}>
          <div className={styles.wrapper}>
            <h3 style={{ fontWeight: 600 }}>Riesgo Inherente</h3>
            <div className={styles.filterList}>
              {inherentRiskList &&
                inherentRiskList.map((risk) => {
                  return (
                    <div key={risk.id} className={styles.checkboxWrapper}>
                      <Checkbox
                        checked={risk.checked}
                        onCheck={onRiskInherentCheck.bind(this, risk.id)}
                        label={
                          <div style={{ display: "flex" }}>
                            <Pill height={16} width={8} color={risk.color} />
                            {risk.label}
                          </div>
                        }
                      />
                    </div>
                  )
                })}
            </div>
          </div>
          <div className={styles.wrapper}>
            <h3 style={{ fontWeight: 600 }}>Riesgo General</h3>
            <div className={styles.filterList}>
              {!generalRiskList && <Loading type='PulseLoader' centered={false} size={8} />}
              {generalRiskList &&
                generalRiskList.map((_generalRisk) => {
                  return (
                    <div key={_generalRisk.id} className={styles.checkboxWrapper}>
                      <Checkbox
                        checked={_generalRisk.checked}
                        onCheck={onGeneralRiskCheck.bind(this, _generalRisk.id)}
                        label={_generalRisk.nombre}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
          <div className={styles.wrapper}>
            <h3>Objetivo Organizacional</h3>
            <div className={styles.filterList}>
              {!organizationalObjectiveList && <Loading type='PulseLoader' centered={false} size={8} />}
              {organizationalObjectiveList &&
                organizationalObjectiveList.map((_organizationalObjective) => {
                  return (
                    <div key={_organizationalObjective.id} className={styles.checkboxWrapper}>
                      <Checkbox
                        checked={_organizationalObjective.checked}
                        onCheck={onOrganizationObjectiveCheck.bind(this, _organizationalObjective.id)}
                        label={_organizationalObjective.nombre}
                      />
                    </div>
                  )
                })}
            </div>
          </div>

          <div className={styles.wrapper}>
            <h3>Macroproceso</h3>
            <div className={styles.filterList}>
              {!macroprocesoList && <Loading type='PulseLoader' centered={false} size={8} />}
              {macroprocesoList &&
                macroprocesoList.map((item) => {
                  return (
                    <div key={item.id} className={styles.checkboxWrapper}>
                      <Checkbox
                        checked={item.checked}
                        onCheck={onMacroprocesoCheck.bind(this, item.id)}
                        label={item.nombre}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <Divider style={{ marginBottom: 15 }} />
        <div style={{ display: "flex", flexDirection: "column", gap: 10, height: "100%", overflow: "hidden" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ alignItems: "center", display: "flex", gap: 5 }}>
              <Checkbox checked={selectAll} onCheck={onSelectAll} />
              <div onClick={onSelectAll}>{t("main_ui.general.btn_select_all")}</div>
            </div>

            {data != null && (
              <span style={{ alignItems: "center", display: "flex" }}>
                <strong>{selectedRisks.length} </strong>&nbsp; Riesgos seleccionados
              </span>
            )}
            <InputSearch onValueChange={onSearchHandler} />
          </div>

          {data == null ? (
            <Loading text={t("main_ui.general.lb_loading_records")} centered={false} />
          ) : (
            <div style={{ maxHeight: 500, overflowY: "auto" }}>
              <AdvancedTable
                stickyHeader
                data={sortData().filter((el) => el.visible)}
                displayedColumns={[
                  "checkbox",
                  "name",
                  "riskType",
                  "organizationalObjective",
                  "inherentRisk",
                  "residualRisk"
                ]}
                config={{
                  rows: {
                    checkbox: {
                      headerCell: "",
                      cell: (row) => {
                        return <Checkbox checked={row.checked} onCheck={onCheckRow.bind(this, row)} />
                      }
                    },
                    name: {
                      headerCell: t("frontend.auditoria.treeitem.riesgo"),
                      headerCellStyle: { width: "40%" },
                      cell: (row) => {
                        const color = findFromConfigList(riesgoRiRanges, row.riesgo_inherente, "color") ?? "#000"

                        return (
                          <div style={{ display: "flex", gap: 5, textAlign: "left" }}>
                            <div style={{ flex: 0, marginTop: 5 }}>
                              <Icon name='tree/risk' size={16} color={color} />
                            </div>
                            <div style={{ flex: 1 }}>{row.codigo + " - " + row.nombre}</div>
                          </div>
                        )
                      }
                    },
                    riskType: {
                      headerCell: t("frontend.auditoria.riesgo.fields.riesgo_general"),
                      cell: (row) => {
                        return row.riesgo_general !== null ? row.riesgo_general?.nombre : "-"
                      }
                    },
                    organizationalObjective: {
                      headerCell: t("frontend.auditoria.riesgo.fields.objetivo__plural"),
                      cell: (row) => {
                        return row.objetivos.length > 0 ? row.objetivos[0]?.nombre : "-"
                      }
                    },
                    inherentRisk: {
                      headerCell: t("frontend.auditoria.riesgo.fields.riesgo_inherente"),
                      sortable: true,
                      cell: (row) => {
                        if (!row.riesgo_inherente) {
                          return "-"
                        }

                        if (riesgoRiRanges === null) {
                          return <div className='ui loader inline active mini'></div>
                        }

                        const color = findFromConfigList(riesgoRiRanges, row.riesgo_inherente, "color")
                        const label = findFromConfigList(riesgoRiRanges, row.riesgo_inherente, "name")

                        return (
                          <div style={{ display: "flex" }}>
                            <div style={{ backgroundColor: color, height: 12, width: 6, borderRadius: 6 }}></div>
                            <div
                              style={{
                                color: "#808080",
                                fontFamily: '"Open Sans", sans-serif',
                                fontSize: 12,
                                paddingLeft: 4
                              }}
                            >
                              {label}
                            </div>
                          </div>
                        )
                      }
                    },
                    residualRisk: {
                      headerCell: t("frontend.auditoria.riesgo.fields.riesgo_residual"),
                      sortable: true,
                      cell: (row) => {
                        if (!row.riesgo_residual) {
                          return "-"
                        }

                        if (riesgoRiRanges === null) {
                          return <div className='ui loader inline active mini'></div>
                        }

                        const color = findFromConfigList(riesgoRiRanges, row.riesgo_residual, "color")
                        const label = findFromConfigList(riesgoRiRanges, row.riesgo_residual, "name")

                        return (
                          <div style={{ display: "flex" }}>
                            <div style={{ backgroundColor: color, height: 12, width: 6, borderRadius: 6 }}></div>
                            <div
                              style={{
                                color: "#808080",
                                fontFamily: '"Open Sans", sans-serif',
                                fontSize: 12,
                                paddingLeft: 4
                              }}
                            >
                              {label}
                            </div>
                          </div>
                        )
                      }
                    }
                  }
                }}
                onRowClick={(row) => {
                  onCheckRow(row, !row.checked)
                }}
                showPaginator={false}
                paginatorConfig={{
                  columnSort,
                  onChangeSort
                }}
                sortable
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const AddEvaluacionDialog = ({ empresa, excludeIds = [], onSubmit, onClose }) => {
  const [list, setList] = useState(null)
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    getEvaluations({
      empresa,
      mode: "subdivisiones",
      entriesPerPage: 1000,
      ano_evaluacion: new Date().getFullYear(),
      only_last_ano: 1
    }).then(({ data }) => {
      setList(data.records)
    })
  }, [])

  const handleOnChange = (id, type, extra = {}) => {
    return () => setSelected({ type, id, ...extra })
  }

  const handleSubmit = () => {
    onSubmit(selected)
  }

  const renderList = () => {
    if (list === null) {
      return <div className='ui loader active'></div>
    }

    return (
      <div className={styles.AddEvaluacionDialogList}>
        <p>{t("frontend.auditoria.creation.new_scope.add_evaluacion__description")}</p>
        <div className='ui list selection divided' style={{ maxHeight: "40vh", overflowY: "auto" }}>
          {list.map((item) => {
            return (
              <div
                key={item.id}
                className={cx(
                  "item",
                  styles.item,
                  excludeIds.includes(item.id) && [styles.disabled, "disabled"],
                  selected?.id == item.id && styles.active
                )}
              >
                <label>
                  <input
                    type='radio'
                    checked={selected?.id == item.id}
                    disabled={excludeIds.includes(item.id)}
                    onChange={handleOnChange(item.id, "evaluacion")}
                  />
                  <span style={{ display: "inline-block", marginLeft: 10 }}>{item.fullname}</span>
                  {excludeIds.includes(item.id) && (
                    <span className='ui small blue label'>{t("main_ui.general.lb_selected_already")}</span>
                  )}
                </label>
                {item.subdivisiones?.length > 0 && (
                  <>
                    <div className={cx("list celled", styles.sublist)}>
                      <div>
                        <div className='header'>{t("frontend.evaluacion.subdivision.__list__")}</div>
                      </div>
                      {item.subdivisiones.map((subitem) => {
                        return (
                          <div
                            key={subitem.id}
                            className={cx(
                              "item",
                              styles.item,
                              excludeIds.includes(subitem.id) && [styles.disabled, "disabled"],
                              selected?.id == subitem.id && styles.active
                            )}
                          >
                            <label>
                              <input
                                type='radio'
                                checked={selected?.id == subitem.id}
                                onChange={handleOnChange(subitem.id, "subdivision", { evaluacionId: item.id })}
                              />
                              <span style={{ display: "inline-block", marginLeft: 10 }}>{subitem.nombre}</span>
                              {excludeIds.includes(subitem.id) && (
                                <span className='ui small blue label'>{t("main_ui.general.lb_selected_already")}</span>
                              )}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className='ui button olive small'
            handleClick={handleSubmit}
            disabled={!selected?.id}
            text={t("main_ui.general.btn_ok")}
          />
        </div>
      </div>
    )
  }

  return (
    <Dialog isOpen>
      <Dialog.Header theme={"light"} buttonClose handleClose={onClose}>
        {t("frontend.auditoria.creation.new_scope.btn_add_evaluacion")}
      </Dialog.Header>
      <Dialog.Body theme={"light"}>
        <div className='ui segment basic'>{renderList()}</div>
      </Dialog.Body>
    </Dialog>
  )
}

function ScopeRisk({
  onBack = () => {},
  onCreate = () => {},
  riskEvaluationData = "",
  divisionId = null,
  empresaId = null
}) {
  const selectableTemplates = useContext(TemplateContext)

  const [step, setStep] = useState(() => (selectableTemplates?.length == 0 ? 2 : 1))
  const [groups, setGroups] = useState([[empresaId, riskEvaluationData, divisionId]])
  const [selectedRisks, setSelectedRisks] = useState({})
  const [openAdd, setOpenAdd] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const { t } = useTranslation()

  const getSelectedRisks = () => {
    let flatten = []

    Object.values(selectedRisks).forEach((id) => {
      flatten = flatten.concat(id)
    })

    return flatten
  }

  const goBackHandler = () => {
    onBack()
  }

  const handleChange = (groupIdx) => {
    return (ids) => {
      setSelectedRisks((prev) => {
        return { ...prev, [groupIdx]: ids }
      })
    }
  }

  const onCreateHandler = () => {
    const from_evaluaciones = []

    groups.forEach((group) => {
      from_evaluaciones.push({ id: group[1], subdivision: group[2] })
    })

    const payload = { selectedRisks: getSelectedRisks(), scopeType: "risks", from_evaluaciones }

    if (selectedTemplate) {
      payload.template = selectedTemplate
    }

    onCreate(payload)
  }

  const handleAddClick = () => {
    setOpenAdd(true)
  }

  const handleAddClose = () => {
    setOpenAdd(false)
  }

  const handleSubmitAdd = (v) => {
    setGroups((prev) => {
      const unidadId = v.type == "evaluacion" ? v.id : v.evaluacionId
      const divisionId = v.type == "evaluacion" ? null : v.id

      return prev.concat([[empresaId, unidadId, divisionId]])
    })
    handleAddClose()
  }

  const handleDeleteGroup = (idx) => {
    return () => {
      setGroups((prev) => prev.filter((x, k) => k != idx))
    }
  }

  const getExcludeIds = () => {
    const exclude = []

    groups.forEach((group) => {
      if (group[2]) {
        exclude.push(group[2])
      } else {
        exclude.push(group[1])
      }
    })

    return exclude
  }

  const handleTemplateChange = (v) => {
    setSelectedTemplate(v)
    setStep(2)
  }

  const getTemplateName = useCallback(() => {
    if (selectedTemplate === null) {
      return null
    } else if (selectedTemplate === "auditoria_base") {
      return t("frontend.auditoria.template.choices.default_template")
    } else {
      return selectableTemplates.find((x) => `@template:${x.id}` == selectedTemplate)?.name
    }
  }, [selectedTemplate])

  const hasChecked = getSelectedRisks().length > 0
  const hasGroupsEmpty = Object.values(selectedRisks).some((x) => x.length == 0)

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <AuditTemplateSelector value={selectedTemplate} onChange={handleTemplateChange} />
            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 10, flex: 1 }}>
              <Button text='btn_prev' handleClick={goBackHandler} className='ui button' />
              <Button
                text={t("btn_next")}
                name='normal'
                type='big'
                handleClick={() => setStep(2)}
                disabledText={{
                  "frontend.auditoria.creation.new_scope.error_template_empty": selectedTemplate === null
                }}
              />
            </div>
          </>
        )
      case 2:
        return (
          <>
            <div style={{ textAlign: "center" }}>
              {t("frontend.auditoria.creation.new_scope.lb_selected_template")}:
              <a className='clickable' onClick={() => setStep(1)}>
                <span className='ui orange strong text'>{getTemplateName()}</span> <i className='icon pencil orange' />
              </a>
            </div>
            <div className='ui divider' />
            {groups.map((group, k) => {
              return (
                <div key={k}>
                  <ScopeRiskGroup
                    idx={k + 1}
                    onChange={handleChange(k)}
                    empresaId={group[0]}
                    riskEvaluationData={group[1]}
                    divisionId={group[2]}
                    defaultOpen={k == 0}
                    onDelete={k > 0 ? handleDeleteGroup(k) : null}
                  />
                </div>
              )
            })}

            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
              <button className='ui button green small compact' onClick={handleAddClick}>
                <i className='ui icon plus' /> {t("frontend.auditoria.creation.new_scope.btn_add_evaluacion")}
              </button>
              {openAdd && (
                <AddEvaluacionDialog
                  excludeIds={getExcludeIds()}
                  empresa={empresaId}
                  onClose={handleAddClose}
                  onSubmit={handleSubmitAdd}
                />
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 10, flex: 1 }}>
              <Button text='btn_back' handleClick={() => setStep(1)} className='ui button' />
              <Button
                text={t("frontend.auditoria.creation.btn_create")}
                name='normal'
                type='big'
                disabledText={{
                  "frontend.auditoria.creation.new_scope.type_risk_filter__error_risk_required": !hasChecked,
                  "frontend.auditoria.creation.new_scope.type_risk_filter__error_riskgroup_empty": hasGroupsEmpty
                }}
                disabled={!hasChecked}
                handleClick={onCreateHandler}
              />
            </div>
          </>
        )
    }
  }

  return renderStepContent()
}

function ScopeProcessGroup({ defaultOpen = false, onChange, riskEvaluationData, divisionId = null, onDelete }) {
  const [groupName, setGroupName] = useState(null)
  const [open, setOpen] = useState(defaultOpen)
  const [selectedIds, setSelectedIds] = useState([])

  const { t } = useTranslation()

  const filterGritems = (row) => {
    if (!row.item_tipo.startsWith("GR_")) {
      return false
    } else if (["GR_UNIDAD_NEGOCIO"].includes(row.item_tipo)) {
      row.checkable = false
    }

    return row
  }

  useEffect(() => {
    const params = {}
    if (divisionId) {
      params.subdivision = divisionId
    }
    getListObjectGenericAction(riskEvaluationData, "record_name", params).then(({ data }) => {
      let name = data.evaluacion.name
      if (data.subdivision?.name) {
        name += " > " + data.subdivision?.name
      }
      setGroupName(name)
    })
  }, [])

  useEffect(() => {
    onChange(selectedIds)
  }, [selectedIds])

  const handleSelect = (v) => {
    setSelectedIds(v)
  }

  return (
    <div className={styles.group}>
      <h3 className={cx("ui top attached header", styles.groupTitle)}>
        {groupName ? (
          <div onClick={() => setOpen((prev) => !prev)}>
            <span>
              <i className='ui icon caret right' /> {groupName}
            </span>
            <div style={{ display: "flex" }}>
              {onDelete && (
                <DeleteItemAction onDelete={onDelete} confirmText={t("main_ui.general.delete_confirmation")} />
              )}
              <span className='ui label circular blue'>{selectedIds.length}</span>
            </div>
          </div>
        ) : (
          <div className='ui loader inline active mini'></div>
        )}
      </h3>
      <div className='ui attached segment' style={{ display: open ? "block" : "none" }}>
        <p style={{ textAlign: "left" }}>Seleccione los procesos que desea auditar en esta Auditoría:</p>

        <ProjectTree
          projectId={riskEvaluationData}
          divisionId={divisionId}
          projectType='evaluacion'
          multiple
          defaultValue={selectedIds}
          onSelect={handleSelect}
          filterRow={filterGritems}
        />
      </div>
    </div>
  )
}

function ScopeProcess({
  onBack = () => {},
  onCreate = () => {},
  riskEvaluationData = "",
  divisionId = null,
  empresaId = null
}) {
  const selectableTemplates = useContext(TemplateContext)

  const [step, setStep] = useState(() => (selectableTemplates?.length == 0 ? 2 : 1))
  const [groups, setGroups] = useState([[empresaId, riskEvaluationData, divisionId]])
  const [selectedIds, setSelectedIds] = useState({})
  const [openAdd, setOpenAdd] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const { t } = useTranslation()

  const getSelectedIds = () => {
    let flatten = []

    Object.values(selectedIds).forEach((id) => {
      flatten = flatten.concat(id)
    })

    return flatten
  }

  const goBackHandler = () => {
    onBack()
  }

  const handleChange = (groupIdx) => {
    return (ids) => {
      setSelectedIds((prev) => {
        return { ...prev, [groupIdx]: ids }
      })
    }
  }

  const onCreateHandler = () => {
    const from_evaluaciones = []

    groups.forEach((group) => {
      from_evaluaciones.push({ id: group[1], subdivision: group[2] })
    })

    const payload = { selectedItems: getSelectedIds(), scopeType: "items", from_evaluaciones }
    if (selectedTemplate) {
      payload.template = selectedTemplate
    }

    onCreate(payload)
  }

  const handleAddClick = () => {
    setOpenAdd(true)
  }

  const handleAddClose = () => {
    setOpenAdd(false)
  }

  const handleSubmitAdd = (v) => {
    setGroups((prev) => {
      const unidadId = v.type == "evaluacion" ? v.id : v.evaluacionId
      const divisionId = v.type == "evaluacion" ? null : v.id

      return prev.concat([[empresaId, unidadId, divisionId]])
    })
    handleAddClose()
  }

  const handleDeleteGroup = (idx) => {
    return () => {
      setGroups((prev) => prev.filter((x, k) => k != idx))
    }
  }

  const getExcludeIds = () => {
    const exclude = []

    groups.forEach((group) => {
      if (group[2]) {
        exclude.push(group[2])
      } else {
        exclude.push(group[1])
      }
    })

    return exclude
  }

  const handleTemplateChange = (v) => {
    setSelectedTemplate(v)
    setStep(2)
  }

  const getTemplateName = useCallback(() => {
    if (selectedTemplate === null) {
      return null
    } else if (selectedTemplate === "auditoria_base") {
      return t("frontend.auditoria.template.choices.default_template")
    } else {
      return selectableTemplates.find((x) => `@template:${x.id}` == selectedTemplate)?.name
    }
  }, [selectedTemplate])

  const hasChecked = getSelectedIds().length > 0
  const hasGroupsEmpty = Object.values(selectedIds).some((x) => x.length == 0)

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <AuditTemplateSelector value={selectedTemplate} onChange={handleTemplateChange} />
            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 10, flex: 1 }}>
              <Button text='btn_prev' handleClick={goBackHandler} className='ui button' />
              <Button
                text={t("btn_next")}
                name='normal'
                type='big'
                handleClick={() => setStep(2)}
                disabledText={{
                  "frontend.auditoria.creation.new_scope.error_template_empty": selectedTemplate === null
                }}
              />
            </div>
          </>
        )
      case 2:
        return (
          <>
            <div style={{ textAlign: "center" }}>
              {t("frontend.auditoria.creation.new_scope.lb_selected_template")}:
              <a className='clickable' onClick={() => setStep(1)}>
                <span className='ui orange strong text'>{getTemplateName()}</span> <i className='icon pencil orange' />
              </a>
            </div>
            <div className='ui divider' />
            {groups.map((group, k) => {
              return (
                <div key={k}>
                  <ScopeProcessGroup
                    idx={k + 1}
                    onChange={handleChange(k)}
                    empresaId={group[0]}
                    riskEvaluationData={group[1]}
                    divisionId={group[2]}
                    defaultOpen={k == 0}
                    onDelete={k > 0 ? handleDeleteGroup(k) : null}
                  />
                </div>
              )
            })}

            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
              <button className='ui button green small compact' onClick={handleAddClick}>
                <i className='ui icon plus' /> {t("frontend.auditoria.creation.new_scope.btn_add_evaluacion")}
              </button>
              {openAdd && (
                <AddEvaluacionDialog
                  excludeIds={getExcludeIds()}
                  empresa={empresaId}
                  onClose={handleAddClose}
                  onSubmit={handleSubmitAdd}
                />
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 10, flex: 1 }}>
              <Button text='btn_back' handleClick={() => setStep(1)} className='ui button' />
              <Button
                text={t("frontend.auditoria.creation.btn_create")}
                name='normal'
                type='big'
                disabledText={{
                  "frontend.auditoria.creation.new_scope.type_item_filter__error_item_required": !hasChecked,
                  "frontend.auditoria.creation.new_scope.type_item_filter__error_itemgroup_empty": hasGroupsEmpty
                }}
                disabled={!hasChecked}
                handleClick={onCreateHandler}
              />
            </div>
          </>
        )
    }
  }

  return renderStepContent()
}
